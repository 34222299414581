@font-face {
  font-family: LevelOne;
  src: url("LevelOne.eb8cb67d.woff") format("woff");
}

body {
  background-color: #ddd;
}

section {
  background-color: #222;
  border-radius: 4px;
  margin-bottom: 3rem;
  padding: 10px 15px;
  box-shadow: 0 7px 8px -4px #0003, 0 12px 17px 2px #00000024, 0 5px 22px 4px #0000001f;
}

header {
  background-image: linear-gradient(#00000080, #00000080), url("hero.ee961b47.jpg");
  background-position: center;
  background-repeat: no-repeat;
  height: 400px;
}

ul {
  font-family: LevelOne;
  font-size: .9em;
}

a {
  color: #59cbe8;
}

a:hover {
  color: #ef9d38;
}

header img {
  width: 300px;
  margin: 0 auto;
  display: block;
}

header b {
  color: #59cbe8;
}

header h1 {
  text-align: center;
  margin-top: 60px;
  font-family: LevelOne, Helvetica;
}

section h1, section h2 {
  text-transform: uppercase;
  font-family: LevelOne, Helvetica;
}

section p {
  font-family: LevelOne, Helvetica;
}

#portfolio {
  grid-template: auto / auto auto;
  column-gap: 8px;
  display: grid;
}

#portfolio div {
  margin: 2px;
}

#portfolio p {
  margin-left: 4px;
  font-size: .8em;
}

footer {
  color: #222;
  grid-template: auto / auto auto auto;
  display: grid;
}

footer p {
  font-family: Helvetica;
  font-size: .5em;
  display: inline-grid;
}

p.copyright, p.version {
  font-size: .4em;
}

#cookiebanner {
  color: #fff;
  z-index: 999;
  background-color: #297cb7;
  border-radius: 8px;
  justify-content: space-between;
  width: 300px;
  margin: 16px;
  font-family: LevelOne;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

#cookiebanner span {
  margin: 15px;
}

#cookiebanner button {
  font-family: LevelOne;
}

span.icon-cookie {
  background-image: url("cookie.631af3d1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80px;
  height: 40px;
  display: block;
  position: relative;
}

span.icon-strategy {
  float: right;
  background-image: url("strategy.ed5dd845.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  display: block;
  position: relative;
}

span.icon-development {
  float: right;
  background-image: url("development.beadc5b8.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin-top: 5px;
  display: block;
  position: relative;
}

span.icon-github {
  float: right;
  background-image: url("github.928f50cf.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin-top: 8px;
  margin-right: 8px;
  display: block;
  position: relative;
}

span.icon-docs {
  float: right;
  background-image: url("docs.53db310c.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin-top: 8px;
  margin-right: 8px;
  display: block;
  position: relative;
}

span.icon-osc {
  float: right;
  background-image: url("osc-logo.780e4ceb.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin-top: 8px;
  margin-right: 8px;
  display: block;
  position: relative;
}

span.icon-sustainability {
  float: right;
  filter: invert();
  background-image: url("sustainability.687944b1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  margin-top: 8px;
  margin-right: 8px;
  display: block;
  position: relative;
}

span.image-career {
  float: left;
  background-image: url("career.246ad2cb.png");
  background-repeat: no-repeat;
  background-size: 200%;
  width: 250px;
  height: 300px;
  margin-right: 20px;
  display: block;
  position: relative;
}

.logo-grid {
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  display: grid;
}

ul.link-grid {
  grid-template: auto / auto auto auto auto;
  margin: 0 auto;
  padding-left: 0;
  display: grid;
}

.link-grid li {
  margin-bottom: 10px;
  padding: 4px;
  list-style-type: none;
  display: inline-grid;
}

.link-grid a {
  color: #fff;
  text-align: center;
  background-color: #297cb7;
  border-radius: 4px;
  padding: 8px;
  font-size: 1.2em;
  text-decoration: none;
}

.social-grid {
  grid-template: auto / auto auto auto;
  margin: 0 auto;
  display: grid;
}

.social-instagram {
  background-image: url("instagram.ecb3802c.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.social-linkedin {
  background-image: url("linkedin.f0a4fa94.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  position: relative;
}

.social-twitter {
  background-image: url("twitter.2c1f249f.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-dpp {
  background-image: url("dpp.a2098d04.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-srt {
  background-image: url("srt.b9e13cbf.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-ra {
  background-image: url("ra.b8e58cd3.gif");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-ctawave {
  background-image: url("cta-wave.7f6f3340.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-dpp-ct {
  float: right;
  background-image: url("dpp-ctsus.9d952c81.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 120px;
  height: 120px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-dashif {
  float: right;
  background-image: url("dashif-logo.3d92863d.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  margin: 0 auto;
  display: block;
  position: relative;
}

span.logo-ncp {
  float: right;
  background-image: url("ncp-logo.55a17121.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  margin: 0 auto;
  display: block;
  position: relative;
}

eyevinn-video {
  margin: 2px;
}

#contact-form {
  color: #000;
  z-index: 10;
  background-color: #fff;
  border-radius: 9px;
  width: 500px;
  padding-top: .6em;
  padding-left: .6em;
  padding-right: .6em;
  font-family: LevelOne;
  transition: width .5s ease-in-out, bottom .5s ease-in-out;
  position: fixed;
  bottom: 20px;
  left: 40px;
}

#contact-form.collapsed {
  z-index: 10;
  border-radius: 6px 6px 0 0;
  width: 100px;
  height: 40px;
  position: fixed;
  bottom: 0;
  left: 40px;
}

#contact-form small {
  font-size: .5em;
}

#contact-form p {
  font-size: .8em;
}

#contact-form label {
  display: inline-block;
}

#contact-form input {
  color: #000;
  background-color: #fff;
  border: 1px solid #59cbe8;
  width: 100%;
  height: calc(2.25em + 2px);
  font-family: LevelOne;
}

#contact-form textarea {
  color: #000;
  background-color: #fff;
  border: 1px solid #59cbe8;
  width: 100%;
  font-family: LevelOne;
}

#contact-form button {
  font-family: LevelOne;
}

#contact-form-button-open:hover {
  cursor: pointer;
}

.btn-osc-signup {
  color: #fff;
  text-align: center;
  background-color: #297cb7;
  border-radius: 4px;
  padding: 8px;
  font-size: 1.2em;
  text-decoration: none;
}

@media (width <= 600px) {
  #portfolio {
    grid-template: auto / auto;
    display: grid;
  }

  .logo-grid {
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(1, 1fr);
    margin: 0 auto;
    display: grid;
  }

  ul.link-grid {
    grid-template: auto / auto;
    margin: 0 auto;
    display: grid;
  }

  #contact-form {
    width: 300px;
  }

  span.image-career {
    width: 100%;
  }

  footer {
    grid-template: auto / auto;
    display: grid;
  }

  footer p {
    font-size: .8em;
  }
}

/*# sourceMappingURL=index.32361b91.css.map */
